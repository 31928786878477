<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card>
          <b-row>

            <!--Date-->
            <b-col xl="4" md="4" sm="12">
              <b-form-group>
                <validation-provider #default="{ errors }" name="date" rules="required">
                  <h5 class="font-weight-bold">{{ $t('Date') }}</h5>
                  <flat-pickr v-model="date" class="form-control"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!--Btn Send-->
            <b-col xl="2" md="3" sm="12" class="align-middle">
              <b-button class="mt-2 mb-2" :disabled="loadingJourney || loadingHistory" variant="primary" type="submit"
                        @click.prevent="validationForm">
                {{ $t('Send') }}
              </b-button>
            </b-col>
          </b-row>

        </b-card>

      </b-form>
    </validation-observer>

    <b-row>
      <!--Map-->
      <b-col xl=" 6" md="6" sm="12">
        <b-card class="card-journey">
          <div style="display: inline;">
            <h3 class="float-left">{{ $t('Location') }}</h3>
            
          </div>
          <l-map ref="myMapJourney" id="myMapJourney" :zoom="zoom" :center="center" :key="'myMapJourney' + 4">
            <l-tile-layer :url="mapUrl"></l-tile-layer>

            <l-marker ref="pins" v-for="(stop, i) in journeyData.stops" :lat-lng="[stop.lat,stop.lng]">
              <l-icon class-name="pinJourney">
                <div class="headline">
                  <span style="color:  #ff0000; padding: 5px;">1</span>
                </div>
              </l-icon>
              <l-tooltip>
                <div>
                  <div>⏰ {{ $t('Time') }}:
                    <strong>{{ new Date(stop.arrive).toLocaleTimeString().substring(0, 5) }}</strong></div>
                </div>
              </l-tooltip>
            </l-marker>
            <l-polyline :opacity="0.6" :lat-lngs="latlngs" :color="polyline.color"></l-polyline>
          </l-map>
        </b-card>
      </b-col>

      <!--Informations-->
      <b-col xl="6" md="6" sm="12">
        <!--Journey-->
        <b-card class="card-journey">
          <b-tabs pills>

            <!-- Tab: Stops -->
            <b-tab active>
              <template #title>
                <feather-icon icon="OctagonIcon" size="16" class="mr-0 mr-sm-50"/>
                <span class="d-none d-sm-inline">{{ $t('Stops') }}</span>
              </template>
              <hr>
              <div class="mt-2 pt-75 ">
                <div v-if="journeyData.stops && journeyData.stops.length > 0 && !loadingJourney && !loadingHistory ">

                  <app-timeline>

                    <app-timeline-item v-for="(stop, i) in journeyData.stops"
                                       :title="resolveTitle(i, stop.arrive, stop.departure, journeyData.stops.length)"
                                       :subtitle=" stop.addressComplete ?  stop.addressComplete : `Lat ${stop.lat}, Lng: ${stop.lng}`"
                                       :time="(stop.stayTime && stop.stayTime != '00:00:00') ? `⏳ ${$t('Permanence')}: ${ stop.stayTime.substring(0, 5) }`:``"/>

                  </app-timeline>

                </div>
                <div v-else-if="loadingJourney == true || loadingHistory == true">
                  <div class="text-center">
                    <b-spinner variant="primary" label="Text Centered"/>
                  </div>

                </div>
                <div v-else class="p1">
                  <h3>{{ $t('no data ...') }}</h3>
                </div>

              </div>
            </b-tab>

            <!-- Tab: Ride -->
            <b-tab>
              <template #title>
                <feather-icon icon="NavigationIcon" size="16" class="mr-0 mr-sm-50"/>
                <span class="d-none d-sm-inline">{{ $t('Ride') }}</span>
              </template>
              <hr>
              <div class="mt-2 pt-75 ">
                <div v-if="journeyData.rides && journeyData.rides.length > 0" class="mb-1 p-1 card-telemetry">
                  <div v-for="(ride, i) in journeyData.rides">
                    <app-timeline>
                      <app-timeline-item
                          :title="`▶ ${new Date(ride.origin.departure).toLocaleTimeString().substring(0, 5)}`"
                          :subtitle="ride.origin.addressComplete ? ride.origin.addressComplete : `Lat: ${ride.origin.lat} Lng: ${ride.origin.lng}`"/>

                      <app-timeline-item
                          :title="`🏁 ${new Date(ride.destination.arrive).toLocaleTimeString().substring(0, 5)}`"
                          :subtitle="ride.destination.addressComplete ? ride.destination.addressComplete : `Lat: ${ride.destination.lat} Lng: ${ride.destination.lng}`"/>
                    </app-timeline>
                    <b-badge variant="light-primary" class="badge-glow  mt-1 mb-2">
                      {{ $t('Travelled Distance') }}: {{ ride.distanceInMeters }} Km - {{ $t('Total Time') }}:
                      {{ ride.time.substring(0, 5) }}
                    </b-badge>
                  </div>
                </div>
                <div v-else-if="loadingJourney == true || loadingHistory == true">
                  <div class="text-center">
                    <b-spinner variant="primary" label="Text Centered"/>
                  </div>

                </div>
                <div v-else class="p1">
                  <h3> {{ $t('no data ...') }}</h3>
                </div>

              </div>
            </b-tab>

          </b-tabs>

        </b-card>

      </b-col>

    </b-row>
    <!--Modal Create Export-->
    <b-modal ref="modal-export" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop
             hide-header-close>
      <div class="mx-3 text-center">
        <h3>{{ $t('Preparing report') }}...</h3>
        <b-spinner variant="primary" label="Text Centered"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BImg,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BTab,
  BTabs,
  BBadge,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BModal
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import store from '@/store'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'

import queriesStoreModule from '../../queriesStoreModule'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LPolyline,
  LTooltip
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

import L from 'leaflet';

import {
  required,

} from '@validations'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    //Bootstrap
    BCard,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BImg,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BTab,
    BTabs,
    BBadge,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BModal,

    //Leafleet Map
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LPolyline,
    LTooltip,

    //Validations
    ValidationProvider,
    ValidationObserver,

    //time picker
    flatPickr,

    //TimeLine
    AppTimeline,
    AppTimelineItem,

    //ScrollBar
    VuePerfectScrollbar,

  },
  props: {
    myId: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      //Rules
      required,

      //Map
      mapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 4,
      center: [-10.1500034, -67.9163393],
      //center: [47.313220, -1.319482],
      icon: L.icon({

        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      staticAnchor: [16, 37],

      iconSize: 40,

      polyline: {
        latlngs: [
          [47.334852, -1.509485],
          [47.342596, -1.328731],
          [47.241487, -1.190568],
          [47.234787, -1.358337]
        ],
        color: '#1a6395'
      },

      journeyData: {},
      dt: '',
      routeData: [],
      loadingJourney: false,
      loadingHistory: false,

      latlngs: [],
      exportPdf: false

    }
  },
  methods: {

    showModalExport() {
      this.$refs['modal-export'].show()
    },
    hideModalExport() {
      this.$refs['modal-export'].hide()
    },

    async exportJourneyToPDF() {
      this.exportPdf = true
      this.showModalExport()
      const res = await store.dispatch('app-queries/exportJourneyToPDF', {
        id: this.myId,
        date: this.date,

      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `journey_${this.date}.pdf`);
        document.body.appendChild(link);
        link.click();

        this.exportPdf = false
        this.hideModalExport()
      });
    },

    resolveTitle(i, arrive, departure, length) {
      if (i == 0) return `▶ ${this.$t('Start')}: ${new Date(departure).toLocaleTimeString().substring(0, 5)}`
      if (i == length - 1) return `🏁 ${this.$t('End')}: ${new Date(arrive).toLocaleTimeString().substring(0, 5)}`
      return `🛑 ${this.$t('Arrival')} ${new Date(arrive).toLocaleTimeString().substring(0, 5)} - ${this.$t('Departure')} ${new Date(departure).toLocaleTimeString().substring(0, 5)}`
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          const dts = new Date(this.date);
          dts.setDate(dts.getDate() + 1)

          dts.setHours(0);
          dts.setMinutes(0);
          this.dt = dts.toISOString()
          this.fetchJourney()
          this.fetchRouteHistory()
        }

      })
    },

    async fetchJourney() {

      this.journeyData = {
        rides: [],
        stops: []
      }

      this.loadingJourney = true

      const res = await store.dispatch('app-queries/fetchJourney', {

        id: this.myId,
        date: this.date,
        //page: page
        //dtE: dtf.toISOString(),
      })
          .catch(e =>{
            this.showSnackbar({
              title: this.$t('Ocorreu um erro inesperado'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            })
            this.loadingJourney = false
            return
          });

      if ((res.data.rides && res.data.rides.length > 0) || (res.data.stops && res.data.stops.length > 0)) {

        if (res.data.rides && res.data.rides.length > 0) {
          res.data.rides.map(r => {
            var distanceInKm = r.distanceInMeters / 1000
            distanceInKm = parseFloat(distanceInKm.toFixed(2))
            r.distanceInMeters = distanceInKm.toLocaleString()
            this.journeyData.rides.push(r)
          });
        }

        if (res.data.stops && res.data.stops.length > 0) {
          res.data.stops.map(s => {
            this.journeyData.stops.push(s)
          });
        }
      }
      this.loadingJourney = false

    },

    showSnackbar(config = {title: '', icon: '', variant: 'info'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: config.title,
          icon: config.icon,
          variant: config.variant,
        },
      })
    },

    async fetchRouteHistory() {
      var cf = false
      var page = 1

      this.routeData = []
      this.latlngs = []

      this.loadingHistory = true

      while (cf == false) {
        const res = await store.dispatch('app-queries/fetchRouteHistory', {
          id: this.myId,
          startDate: this.date,
          endDate: this.date,
          page: page
        }).catch(e => {

          this.showSnackbar({
            title: this.$t('Ocorreu um erro inesperado'),
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          })
          this.routeData = []
          this.latlngs = []
          this.loadingHistory = false
          cf = true
          return
        })

        if (res.data.data.length > 0) {

          res.data.data.map(r => {
            if (r.lat && r.lat != 0 && r.lng && r.lng != 0) {
              this.routeData.push(r)
              this.latlngs.push([r.lat, r.lng])
            }
          });

        } else {
          cf = true
          this.loadingHistory = false

          this.$nextTick(() => {

            this.$refs.myMapJourney.mapObject.flyToBounds(
                this.latlngs.map((m, i) => {

                  return [m[0], m[1]];

                })
            );

          })

        }

        page++
      }

    },
  },
  mounted() {
    const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'
    // Register module
    if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
    })
  },
  setup() {

    const latlngs = ref({
      points: [],
      speeds: [],
      times: []
    })

    const dts = new Date();
    dts.setHours(4);
    dts.setMinutes(0);

    const dtf = new Date();

    //const startTime = ref(`04:00`)
    //const endTime = ref(`${dtf.getHours()}:${dtf.getMinutes()}`)
    //const date = ref(`${dtf.getFullYear()}-${dtf.getMonth() + 1}-${dtf.getDate() }`)

    const startTime = ref(``)
    const endTime = ref(``)
    const date = ref(``)

    // Register module
    //if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

    /* UnRegister on leave
    onUnmounted(() => {
        if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
    })*/

    return {
      //QUERIES_APP_STORE_MODULE_NAME,
      latlngs,
      date,
      startTime,
      endTime,

    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.card-journey {

  height: 80vh !important;
}

.no-icon {
  width: 17px;
}

.card-telemetry {
  border-top: 3px solid #00cfe8;
  -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: .428rem;

}

.text-index {
  margin: auto;
  color: white;
  text-shadow: -1px 2px black;
}

.div-img {
  background: #f8f9fa;
  border-radius: 0px 0px 0px 0.428rem;
  border-right: 1px solid #82868b;
}

.index {
  background-color: #00cfe8;
  border-radius: 50%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 2.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  max-width: 40px;
  display: flex;
  color: white;
  width: 2rem;
  max-width: 40px;

}

.pinJourney {
  background-color: #ff0000 !important;
  /*padding: 10px;*/
  border-radius: 50%;
  /*box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);*/
  text-align: center;
  width: 16px !important;
  height: 16px !important;
}

.pinStart {
  background-color: #03dac5 !important;
  /*padding: 10px;*/
  border-radius: 50%;
  /*box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);*/
  text-align: center;
  width: auto !important;
  height: auto !important;
}

.asset-on {
  background-color: #28c76f !important;
}

.asset-off {
  background-color: #82868b !important;
}

.asset-failure {
  background-color: #ef5258 !important;
}

.asset-noconnection {
  background-color: #f8f9fa !important;
}

.asset-severe {
  background-color: #ff9d54 !important;
}

.asset-iddle {
  background-color: #00cfe8 !important;
}

.with-icon {
  width: 40px;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 70.5vh !important;
  }
}

.text-header {
  //font-size: clamp(0.5em, 0.7em + 0.2vw, 5.5em);
  font-size: 1em;
}

//@media
/* @media screen and (min-height: 768px) {
    .vue2leaflet-map.leaflet-container {
        height: 54vh !important;
    }

    .card-journey {
        height: 64vh !important;
    }
}

@media screen and (min-height: 900px) {
    .vue2leaflet-map.leaflet-container {
        height: 60vh !important;
    }

    .card-journey {
        height: 68.6vh !important;
    }
}

@media screen and (min-height: 1024px) {
    .vue2leaflet-map.leaflet-container {
        height: 66vh !important;
    }

    .card-journey {
        height: 74vh !important;
    }
} */
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
