<template>
  <section class="invoice-preview-wrapper">

    <b-tabs>

      <!-- Tab: Route -->
      <b-tab @click="selectTab(1)" :active="active == 0 ? true : false" :title="$t('Routes')" lazy>
        <hr>
        <div class="mt-2 pt-75 ">
          <!-- <report-route v-if="active == 0" :myId="myId"></report-route> -->
          <div>
            <validation-observer ref="simpleRules">
              <b-card>
                <b-form>
                  <b-row class="align-items-end">
                    <b-col cols="12" md="5" lg="4" class="mb-md-0">
                      <h5 class="font-weight-bold">{{ $t('Choose the Date') }}</h5>
                      <div class="d-flex">
                        <flat-pickr id="date-range-picker" v-model="dateRange" :config="flatpickrConfig"
                                    @on-change="ensureAllowedDateRange" class="form-control flat-picker"/>
                        <b-button @click="updateReport" size="sm" variant="primary" :disabled="loadingHistory"
                                  style="border-radius: 0 0.357rem 0.357rem 0;">
                          <feather-icon
                              icon="ArrowRightIcon"
                              size="16"
                          />
                        </b-button>
                      </div>
                    </b-col>
                    <b-col cols="12" md="5" lg="4" class="mb-md-0 mb-3 mt-2" v-show="routeData && routeData.length > 0">
                      <div class="d-flex w-100 align-items-baseline">
                        <b-button :disabled="exportPdf" v-show="routeData && routeData.length > 0"
                                  @click="exportLocationHistoryToExcel" variant="info"
                                  class="d-flex align-items-baseline">
                          <b-spinner v-if="exportPdf" small/>
                          <span class="align-middle ml-50">{{ $t('Export Sheet') }}</span>
                        </b-button>
                        <b-button :disabled="loadingHistory || loadingAddress" @click="showAddress"
                                  v-show="routeData && routeData.length > 0"
                                  variant="primary"
                                  class="d-flex align-items-baseline ml-50">
                          {{ $t(textBtnShowAddress) }}
                        </b-button>
                      </div>

                    </b-col>
                  </b-row>
                </b-form>
              </b-card>
            </validation-observer>
            <b-row>
              <!--Map-->
              <b-col :xl="mapColumns" :md="mapColumns" sm="12">
                <b-card class="card-journey">

                  <div style="display:inline !important ;">
                    <h3 class="float-left">{{ $t('Location') }}</h3>
                    <h5 v-show="loadingHistory" class="mb-2 ml-2 float-right">
                      <b-spinner small/>
                      Gerando Relatório
                    </h5>
                    <h5 v-show="(!routeData || routeData.length == 0 ) && !loadingHistory && !getAddress"
                        class="mb-2 ml-2 float-right">{{ voidMsg }}</h5>
                  </div>
                  <l-map ref="myMapRoute" id="myMapRoute" :zoom="zoom" :center="center" :key=" 'myMapRoute' + mapKey">
                    <l-tile-layer :url="mapUrl"></l-tile-layer>
                    <l-polyline ref="myPolyline" :lat-lngs="latlngs" :color="polyline.color"></l-polyline>
                    <l-marker ref="pins" v-for="(pin, i) in routeData" :lat-lng="[pin.lat, pin.lng]">
                      <l-icon class-name="pinRoute">
                        <div class="headline">
                          <span style="color: #1a6395; padding: 5px;">1</span>
                        </div>
                      </l-icon>
                      <l-tooltip>
                        <div>
                          <div>📆 {{ $t('Day') }}: <strong>{{ formattedDate(pin.dateTime) }}</strong></div>
                          <div>⏰ {{ $t('Time') }}:
                            <strong>{{ new Date(pin.dateTime).toLocaleTimeString().substring(0, 5) }}</strong></div>
                          <div>🚗 {{ $t('Speed') }}: <strong>{{ pin.kmh }} Km/h</strong></div>
                        </div>
                      </l-tooltip>
                    </l-marker>
                  </l-map>
                </b-card>
              </b-col>

              <!--Informations-->
              <b-col xl="6" md="6" sm="12" v-if="getAddress">
                <!--Journey-->
                <b-card class="card-journey">
                  <div class="my-2 pt-75 content-tab">
                    <div v-if="routeData && routeData.length > 0 && !loadingHistory">
                      <b-list-group>
                        <b-list-group-item v-for="(pin, i) in routeData">
                          <div>
                            {{ $t('Time') }}:
                            <strong>{{ new Date(pin.dateTime).toLocaleTimeString().substring(0, 5) }}</strong>&nbsp;&nbsp;
                            Vel: <strong>{{ pin.kmh }} Km/h</strong>
                          </div>
                          <div>
                            {{ $t('Address') }}:
                            <strong>{{
                                pin.addressComplete ? pin.addressComplete : `Lat: ${pin.lat},  Lng: ${pin.lng}`
                              }}</strong>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                      <div class="text-center my-2">
                        <b-button v-if="!loadingAddress && currentRequestLength == 10" @click="composeReportWithAddress"
                                  variant="primary" size="sm">Carregar mais
                        </b-button>
                        <div v-else-if="loadingAddress == true">
                          <b-spinner variant="primary" label="Text Centered"/>
                          <h5>Buscando Endereços</h5>
                        </div>
                        <div class="mt-2" style="height: 1px;"></div>
                      </div>
                    </div>
                    <div v-else-if="loadingHistory == true">
                      <div class="text-center">
                        <b-spinner variant="primary" label="Text Centered"/>
                        <h5>Buscando Endereços</h5>
                      </div>
                    </div>
                    <div v-else-if="loadingAddress == false && routeData && routeData.length == 0">
                      <div class="text-center">
                        <h5>{{ $t('no data') }} ...</h5>
                      </div>
                    </div>
                    <div v-else class="p1">
                      <div class="text-center">
                        <b-spinner variant="primary" label="Text Centered"/>
                        <h5>Buscando Endereços</h5>
                      </div>

                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-tab>

      <!-- Tab: Journey -->
      <b-tab @click="selectTab(2)" :title="$t('Journey')" lazy :active="active == 0 ? false : true ">
        <hr>
        <div class="mt-2 pt-75 ">
          <report-journey v-if="active != 0" :myId="myId"></report-journey>
          <!-- <h3>Jornada</h3> -->
        </div>
      </b-tab>

    </b-tabs>
    <!--Modal Create Export-->
    <b-modal ref="modal-export" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop
             hide-header-close>
      <div class="mx-3 text-center">
        <h3>{{ $t('Preparing report') }}...</h3>
        <b-spinner variant="primary" label="Text Centered"/>
      </div>
    </b-modal>

  </section>
</template>

<script>
import ReportRoute from '../telemetry/components/ReportRoute.vue'
import ReportJourney from '../telemetry/components/ReportJourney.vue'


import router from '@/router'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'

import {required} from '@validations'
import vSelect from 'vue-select'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BImg,
  BFormGroup,
  BForm,
  BFormCheckbox,
  BTab,
  BTabs,
  BBadge,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BModal, BCardTitle, BCardHeader
} from 'bootstrap-vue'

import L from 'leaflet';

import 'leaflet-polylinedecorator';

import 'leaflet/dist/leaflet.css';


import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LPolyline,
  LTooltip
} from 'vue2-leaflet'


import flatPickr from 'vue-flatpickr-component'

import store from '@/store'

import {
  ref,
  onUnmounted
} from '@vue/composition-api'

import queriesStoreModule from '../queriesStoreModule'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  components: {
    BCardHeader, BCardTitle,
    ReportRoute,
    ReportJourney,

    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BImg,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BTab,
    BTabs,
    BBadge,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BModal,

    //Leafleet Map
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LPolyline,
    LTooltip,

    //Validations
    ValidationProvider,
    ValidationObserver,

    //time picker
    flatPickr,
    vSelect,


  },
  data() {
    return {


      //Rules
      required,

      //Map
      mapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 4,
      center: [-10.1500034, -67.9163393],
      //center: [47.313220, -1.319482],
      icon: L.icon({

        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      staticAnchor: [16, 37],

      iconSize: 40,

      polyline: {
        latlngs: [
          [47.334852, -1.509485],
          [47.342596, -1.328731],
          [47.241487, -1.190568],
          [47.234787, -1.358337]
        ],
        color: '#1a6395'
      },

      journeyData: {},
      dt: '',
      routeData: [],
      loadingJourney: false,
      loadingHistory: false,

      latlngs: [],
      end: this.$t('End'),
      exportPdf: false,
      translateExport: this.$t('Export'),

      date: '',
      startTime: '',
      endTime: '',

      flatpickrConfig: {
        maxDate: new Date(),
        mode: 'range',
      },

      getAddress: false,
      options: [
        {value: true, label: 'Sim'},
        {value: false, label: 'Não'}
      ],

      mapColumns: "12",
      textBtnShowAddress: "Exibir Endereços",
      page: 1,
      loadingAddress: false,
      currentRequestLength: 0,
      voidMsg: "",
      mapKey: 1,
      decoratorLayer: null,


    };
  },
  computed: {},
  mounted() {
    const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'
    // Register module
    if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
    })

    this.updateReport()
  },


  methods: {

    formattedDate(dateString){
      const date = new Date(dateString);

      const formattedDate = date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
      
      return formattedDate
    },

    formatDate(date) {
      const month = String(date.getMonth() + 1)
      const formattedMonth = month.length === 1 ? '0' + month : month

      return `${date.getDate()}/${formattedMonth}/${date.getFullYear()}`;
    },

    dateFromString(string) {
      const [date, month, year] = string.split("/")
      return new Date(year, month - 1, date)
    },

    formatDateRange(dateRange, type = "string") {
      const stringValues = typeof dateRange === "string"
          ? dateRange.split(" to ")
          : dateRange.map(date => this.formatDate(date))

      if (type === "string") return stringValues
      return stringValues.map(this.dateFromString)
    },

    updateReport() {
      if(this.getAddress){
        this.composeReportWithAddress()
      }else{
        this.composeReport()
      }
    },

    ensureAllowedDateRange(dates) {
      const formattedDates = this.formatDateRange(dates, "date")

      if (formattedDates.length > 1) {
        const minAllowedDate = new Date(formattedDates[1])
        minAllowedDate.setDate(minAllowedDate.getDate() - 31)

        if (formattedDates[0] < minAllowedDate) {
          this.showSnackbar({
            title: this.$t('Max interval one month'),
            icon: 'InfoIcon',
          })

          this.dateRange = [this.formatDate(minAllowedDate), this.formatDate(formattedDates[1])].join(" to ")
        }
      }
    },

    hasData(response) {
      return response && response.data.data
    },

    updateKeyMap() {
      this.mapKey++
    },

    async composeReportWithAddress() {

      if (this.loadingAddress == true) return

      this.loadingAddress = true
      let response = await this.fetchRouteHistory(this.page)

      if (this.hasData(response)) {
        this.currentRequestLength = response.data.data.length
      }

      this.loadingAddress = false
      if (this.responseIsValid(response)) {
        this.page++
        response.data.data.map(location => {
          if (this.coordinateIsValid(location.lat, location.lng)) {
            this.routeData.push(location)
            this.latlngs.push([location.lat, location.lng])
          }
        });
        if (this.latlngs.length > 0) {
          this.drawRouteOnMap()
        }
      }
    },

    showAddress() {
      this.getAddress = !this.getAddress
      this.clearReport()
      if (this.getAddress) {
        this.textBtnShowAddress = "Ocultar Endereço"
        this.mapColumns = "6"
        this.page = 1
        this.composeReportWithAddress()
      } else {
        this.textBtnShowAddress = "Exibir Endereço"
        this.mapColumns = "12"
        this.composeReport()
      }

    },

    showModalExport() {
      this.$refs['modal-export'].show()
    },
    hideModalExport() {
      this.$refs['modal-export'].hide()
    },
    selectTab(tab) {
      if (tab == 1) this.active = 0
      if (tab == 2) this.active = 1
    },

    async exportLocationToPDF() {
      this.exportPdf = true
      this.showModalExport()
      const res = await store.dispatch('app-queries/exportLocationHistoryToPDF', {
        id: this.myId,
        date: this.date,
        hS: this.startTime,
        hE: this.endTime,

      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `route_${this.date}.pdf`);
        document.body.appendChild(link);
        link.click();
        this.exportPdf = false
        this.hideModalExport()
      });
    },

    async exportLocationHistoryToExcel() {
      this.exportPdf = true
      this.showModalExport()

      const dates = typeof this.dateRange === "string"
          ? this.dateRange.split(" to ")
          : this.dateRange.map(date => this.formatDate(date))
      
      const res = await store.dispatch('app-queries/exportLocationHistoryToExcel', {
            id: this.myId,
            startDate: dates[0],
            endDate: dates[1],
            getAddress: this.getAddress

          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `route_${this.date}.xls`);
            document.body.appendChild(link);
            link.click();
            this.exportPdf = false
            this.hideModalExport()
          }).catch(e => {
            this.showSnackbar({
              title: this.$t('Ocorreu um erro inesperado'),
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            })
          })
          .finally(f => {
            this.exportPdf = false
            this.hideModalExport()
          })
      ;
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.composeReport()
        }
      })
    },

    showSnackbar(config = {title: '', icon: '', variant: 'info'}) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: config.title,
          icon: config.icon,
          variant: config.variant,
        },
      })
    },

    async fetchRouteHistory(page) {
      const dates = typeof this.dateRange === "string"
          ? this.dateRange.split(" to ")
          : this.dateRange.map(date => this.formatDate(date))

      const response = await store.dispatch('app-queries/fetchRouteHistory', {
            id: this.myId,
            getAddress: this.getAddress,
            startDate: dates[0],
            endDate: dates[1],
            page: page
          })
              .catch(e => {
                this.voidMsg = "Nenhuma informação correspondente localizada"
                this.clearReport()
                this.loadingHistory = false

                this.showSnackbar({
                  title: this.$t('Ocorreu um erro inesperado'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger'
                })
              })
      ;


      return response
    },

    clearReport() {
      this.currentRequestLength = 0
      this.page = 1
      this.routeData = []
      this.latlngs = []
      if (this.decoratorLayer) this.decoratorLayer.clearLayers()
      this.decoratorLayer = null
    },

    drawRouteOnMap() {
      this.updateKeyMap()
      this.$nextTick(() => {
        this.$refs.myMapRoute.mapObject.fitBounds(
            this.latlngs.map((coordinate) => {
              return coordinate;
            })
        );

        const simplificationFactor = 0.005;
        const simplifiedArr = this.simplifyPolyline(this.routeData, simplificationFactor);
        const simplifiedPolyline = L.polyline(simplifiedArr);
        this.decoratorLayer = L.polylineDecorator(simplifiedPolyline, {
          patterns: [{
            offset: 5,
            repeat: 30,
            symbol: L.Symbol.arrowHead({
              pixelSize: 10,
              pathOptions: {
                fillOpacity: 1,
                weight: 0
              }
            })
          }]
        }).addTo(this.$refs.myMapRoute.mapObject);
      })
    },

    simplifyPolyline(arr, factor) {
      const simplifiedArr = [...arr];
      for (let i = 1; i < simplifiedArr.length - 1; i++) {
        if (Math.random() < factor) {
          simplifiedArr.splice(i, 1);
          i--;
        }
      }
      return simplifiedArr;
    },

    responseIsValid(response) {
      if (response && response.data && Array.isArray(response.data.data) && response.data.data.length > 0) return true
      return false
    },

    coordinateIsValid(latitude, longitude) {
      const latitudeValid = !isNaN(latitude) && parseFloat(latitude) >= -90 && parseFloat(latitude) <= 90 && parseFloat(latitude) != 0;
      const longitudeValid = !isNaN(longitude) && parseFloat(longitude) >= -180 && parseFloat(longitude) <= 180 && parseFloat(longitude) != 0;
      return latitudeValid && longitudeValid;
    },

    async composeReport() {
      this.voidMsg = "Nenhuma informação correspondente localizada"
      var flag = false
      var page = 1
      this.clearReport()
      this.loadingHistory = true


      while (flag == false) {
        const response = await this.fetchRouteHistory(page)
        if (this.responseIsValid(response)) {
          response.data.data.map(location => {
            if (this.coordinateIsValid(location.lat, location.lng)) {
              this.routeData.push(location)
              this.latlngs.push([location.lat, location.lng])
            }
          });
        } else {
          console.log("routeData", this.routeData)
          console.log("latlngs", this.latlngs)
          flag = true
          this.loadingHistory = false
          if (this.latlngs.length > 0) {
            this.drawRouteOnMap()
          }
        }
        page++
      }
    },

  },

  setup() {
    const myId = router.currentRoute.params.id
    const active = router.currentRoute.query.journey

    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const dateRange = ref([yesterday, new Date()])

    return {
      myId,
      active,
      dateRange
    }
  }

}
</script>

<style lang="scss">

@import '@core/scss/vue/libs/map-leaflet.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.card-journey {

  height: 80vh !important;
}

.card.card-journey .card-body {
  overflow-y: scroll;
}

.no-icon {
  width: 17px;
}

.card-telemetry {
  border-top: 3px solid #00cfe8;
  -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  border-radius: .428rem;

}

.text-index {
  margin: auto;
  color: white;
  text-shadow: -1px 2px black;
}


.index {
  background-color: #00cfe8;
  border-radius: 50%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 2.7rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  max-width: 40px;
  display: flex;
  color: white;
  width: 2rem;
  max-width: 40px;

}

.pinRoute {
  background-color: #1a6395 !important;
  /*padding: 10px;*/
  border-radius: 50%;
  /*box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);*/
  text-align: center;
  width: 16px !important;
  height: 16px !important;
}

.vue2leaflet-map {
  &.leaflet-container {
    height: 70.5vh !important;
  }
}

.text-header {
  //font-size: clamp(0.5em, 0.7em + 0.2vw, 5.5em);
  font-size: 1em;
}

/* 
//@media
@media screen and (min-height: 768px) {
    .vue2leaflet-map.leaflet-container {
        height: 54vh !important;
    }

    .card-journey {
        height: 64vh !important;
    }
}

@media screen and (min-height: 900px) {
    .vue2leaflet-map.leaflet-container {
        height: 60vh !important;
    }

    .card-journey {
        height: 68.6vh !important;
    }
}

@media screen and (min-height: 1024px) {
    .vue2leaflet-map.leaflet-container {
        height: 66vh !important;
    }

    .card-journey {
        height: 74vh !important;
    }
} */
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

</style>
