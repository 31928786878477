<template>
<div>
    <validation-observer ref="simpleRules">
        <b-form>
            <b-row>

                <!--Date-->
                <b-col xl="3" md="3" sm="12">
                    <b-form-group>
                        <validation-provider #default="{ errors }" name="date" rules="required">
                            <h5 class="font-weight-bold">{{$t('Date')}}</h5>
                            <flat-pickr v-model="date" class="form-control" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!--Start Time-->
                <b-col xl="2" md="3" sm="6">
                    <b-form-group>
                        <validation-provider #default="{ errors }" name="start-time" rules="required">
                            <h5 class="font-weight-bold">{{$t('Start Time')}}</h5>
                            <flat-pickr v-model="startTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!--End Time-->
                <b-col xl="2" md="3" sm="6">
                    <b-form-group>
                        <validation-provider #default="{ errors }" name="end-time" rules="required">
                            <h5 class="font-weight-bold">{{$t('End Time')}}</h5>
                            <flat-pickr v-model="endTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </b-col>

                <!--Btn Send-->
                <b-col xl="2" md="2" sm="12" class="align-middle">
                        <b-button class="mt-2 mb-2" variant="primary" type="submit" @click.prevent="validationForm">
                            {{$t('Send')}}
                        </b-button>
                </b-col>

            </b-row>
        </b-form>
    </validation-observer>

    <b-row>
        <!--Map-->
        <b-col xl=" 6" md="6" sm="12">
            <b-card class="card-journey">
                <div style="display:inline !important ;">
                    <h3 class="float-left">{{$t('Location')}}</h3>
                    <b-dropdown :disabled="exportPdf" v-show="routeData && routeData.length > 0" id="dropdown-left" :text="translateExport" variant="info" size="sm" class="mb-2  float-right">
                        <b-dropdown-item @click="exportLocationToPDF">
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">{{$t('Export PDF')}}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="exportLocationHistoryToExcel">
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">{{$t('Export Sheet')}}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <l-map ref="myMapRoute" id="myMapRoute" :zoom="zoom" :center="center" :key="4">
                    <l-tile-layer :url="mapUrl"></l-tile-layer>
                    <l-marker ref="pins" v-for="(pin, i) in routeData" :lat-lng="[pin.lat,pin.lng]">
                        <l-icon class-name="pinRoute">
                            <div class="headline">
                                <span style="color: #1a6395; padding: 5px;">1</span>
                            </div>
                        </l-icon>
                        <l-tooltip>
                            <div>
                                <div>⏰ {{$t('Time')}}: <strong>{{new Date(pin.dateTime).toLocaleTimeString().substring(0, 5)}}</strong></div>
                                <div>🚗 {{$t('Speed')}}: <strong>{{pin.kmh}} Km/h</strong></div>
                            </div>
                        </l-tooltip>
                    </l-marker>
                    <l-polyline :lat-lngs="latlngs" :color="polyline.color"></l-polyline>
                </l-map>
            </b-card>
        </b-col>

        <!--Informations-->
        <b-col xl="6" md="6" sm="12">

            <!--Journey-->
            <b-card class="card-journey">
                <div class="mt-2  pt-75 content-tab">
                    <div v-if="routeData && routeData.length > 0">

                        <b-list-group>
                            <b-list-group-item v-for="(pin, i) in routeData">
                                <div>
                                    {{$t('Time')}}: <strong>{{ new Date(pin.dateTime).toLocaleTimeString().substring(0, 5)}}</strong>&nbsp;&nbsp; Vel: <strong>{{pin.kmh}} Km/h</strong>
                                </div>
                                <div>
                                    {{$t('Address')}}: <strong>{{pin.addressComplete ? pin.addressComplete : `Lat: ${pin.lag},  Lng: ${pin.lng}`}}</strong>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </div>
                    <div v-else-if="loadingHistory == true">
                        <div class="text-center">
                            <b-spinner variant="primary" label="Text Centered" />
                        </div>

                    </div>
                    <div v-else class="p1">
                        <h3> no data ...</h3>
                    </div>

                </div>

            </b-card>

        </b-col>

    </b-row>
    <!--Modal Create Export-->
    <b-modal ref="modal-export" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
        <div class="mx-3 text-center">
            <h3>{{$t('Preparing report')}}...</h3>
            <b-spinner variant="primary" label="Text Centered" />
        </div>
    </b-modal>
</div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    VBToggle,
    BImg,
    BFormGroup,
    BForm,
    BFormCheckbox,
    BTab,
    BTabs,
    BBadge,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BModal

} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'

import store from '@/store'

import {
    ref,
    onUnmounted
} from '@vue/composition-api'

import queriesStoreModule from '../../queriesStoreModule'

import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'

import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup,
    LPolyline,
    LTooltip
} from 'vue2-leaflet'

import 'leaflet/dist/leaflet.css'

import L from 'leaflet';

import {
    required,

} from '@validations'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    components: {
        //Bootstrap
        BCard,
        BCardText,
        BRow,
        BCol,
        BBadge,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableLite,
        BCardText,
        BButton,
        BAlert,
        BLink,
        VBToggle,
        BImg,
        BFormGroup,
        BForm,
        BFormCheckbox,
        BTab,
        BTabs,
        BBadge,
        BListGroup,
        BListGroupItem,
        BSpinner,
        BDropdown,
        BDropdownItem,
        BModal,

        //Leafleet Map
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        LPolyline,
        LTooltip,

        //Validations
        ValidationProvider,
        ValidationObserver,

        //time picker
        flatPickr,

        //TimeLine
        AppTimeline,
        AppTimelineItem,

        //ScrollBar
        VuePerfectScrollbar

    },
    props: {
        myId: {
            type: String,
            required: true,
        },

    },
    data() {
        return {
            //Rules
            required,

            //Map
            mapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 4,
            center: [-10.1500034, -67.9163393],
            //center: [47.313220, -1.319482],
            icon: L.icon({

                iconSize: [32, 37],
                iconAnchor: [16, 37]
            }),
            staticAnchor: [16, 37],

            iconSize: 40,

            polyline: {
                latlngs: [
                    [47.334852, -1.509485],
                    [47.342596, -1.328731],
                    [47.241487, -1.190568],
                    [47.234787, -1.358337]
                ],
                color: '#1a6395'
            },

            journeyData: {},
            dt: '',
            routeData: [],
            loadingJourney: false,
            loadingHistory: false,

            latlngs: [],
            end: this.$t('End'),
            exportPdf: false,
            translateExport: this.$t('Export'),

        }
    },
    methods: {

        showModalExport() {
            this.$refs['modal-export'].show()
        },
        hideModalExport() {
            this.$refs['modal-export'].hide()
        },

        async exportLocationToPDF() {
            this.exportPdf = true
            this.showModalExport()
            const res = await store.dispatch('app-queries/exportLocationHistoryToPDF', {
                id: this.myId,
                date: this.date,
                hS: this.startTime,
                hE: this.endTime,

            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `route_${this.date}.pdf`);
                document.body.appendChild(link);
                link.click();
                this.exportPdf = false
                this.hideModalExport()
            });
        },

        async exportLocationHistoryToExcel() {
            this.exportPdf = true
            this.showModalExport()
            const res = await store.dispatch('app-queries/exportLocationHistoryToExcel', {
                id: this.myId,
                date: this.date,
                hS: this.startTime,
                hE: this.endTime,

            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `route_${this.date}.xls`);
                document.body.appendChild(link);
                link.click();
                this.exportPdf = false
                this.hideModalExport()
            });
        },

        resolveTitle(i, arrive, departure, length) {
            if (i == 0) return `▶ ${this.$t('Start')}: ${ new Date(departure).toLocaleTimeString().substring(0, 5)}`
            if (i == length - 1) return `🏁 ${this.end}: ${new Date(arrive).toLocaleTimeString().substring(0, 5)}`
            return `🛑 Arrival ${new Date(arrive).toLocaleTimeString().substring(0, 5)} - Departure ${new Date(departure).toLocaleTimeString().substring(0, 5)}`
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.fetchRouteHistory()
                }
            })
        },

        async fetchRouteHistory() {
            console.log("Report")
            var cf = false
            var page = 1

            this.routeData = []
            this.latlngs = []

            this.loadingHistory = true

            while (cf == false) {
                /*
                const res = await store.dispatch('app-queries/fetchRouteHistory', {
                    //id: router.currentRoute.params.id
                    id: this.myId,
                    dtS: this.dtS,
                    dtE: this.dtE,
                    date: this.dt,
                    page: page
                    //dtE: dtf.toISOString(),
                });*/

                const res = await store.dispatch('app-queries/fetchRouteHistory', {
                    //id: router.currentRoute.params.id
                    id: this.myId,
                    //dtS: dts.toISOString(),
                    date: this.date,
                    hS: this.startTime,
                    hE: this.endTime,
                    page: page
                    //dtE: dtf.toISOString(),
                });

                // console.log(`Resposta `, resp)

                if (res && res.data.data.length > 0) {
                    console.log(`Resposta `, res)
                    res.data.data.map(r => {
                        if (r.lat && r.lat != 0 && r.lng && r.lng != 0) {
                            this.routeData.push(r)
                            this.latlngs.push([r.lat, r.lng])
                        }
                    });

                } else {

                    cf = true
                    this.loadingHistory = false

                    if (this.latlngs.length > 0) {

                        this.$nextTick(() => {

                            this.$refs.myMapRoute.mapObject.flyToBounds(
                                this.latlngs.map((m, i) => {

                                    return [m[0], m[1]];

                                })
                            );

                        })
                    }

                }

                page++
            }

        },
    },
    mounted() {
        const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'
        // Register module
        if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
        })


        console.log("Report Route")

    },
    setup() {

        const latlngs = ref({
            points: [],
            speeds: [],
            times: []
        })

        const dts = new Date();
        dts.setHours(4);
        dts.setMinutes(0);

        const dtf = new Date();

        //const startTime = ref(`04:00`)
        //const endTime = ref(`${dtf.getHours()}:${dtf.getMinutes()}`)
        //const date = ref(`${dtf.getFullYear()}-${dtf.getMonth() + 1}-${dtf.getDate() }`)

        const dtS = ref(``)
        const dtF = ref(``)
        const date = ref(``)

        return {
            //QUERIES_APP_STORE_MODULE_NAME,
            latlngs,
            date,

            dtS,
            dtF

        }
    }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/map-leaflet.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.card-journey {
    height: 80vh !important;
    //max-height: 550px;
    //overflow: auto;
}

.card-map {
    height: 80vh !important;
    //max-height: 550px;
    overflow: auto;
}

.no-icon {
    width: 17px;
}

.card-telemetry {
    border-top: 3px solid #00cfe8;
    -webkit-box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    border-radius: .428rem;

}

.text-index {
    margin: auto;
    color: white;
    text-shadow: -1px 2px black;
}

.div-img {
    background: #f8f9fa;
    border-radius: 0px 0px 0px 0.428rem;
    border-right: 1px solid #82868b;
}

.index {
    background-color: #00cfe8;
    border-radius: 50%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    width: 2.7rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    max-width: 40px;
    display: flex;
    color: white;
    width: 2rem;
    max-width: 40px;

}

.pinRoute {
    background-color: #1a6395;
    /*padding: 10px;*/
    border-radius: 50%;
    /*box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);*/
    text-align: center;
    width: auto !important;
    height: auto !important;
}

.asset-on {
    background-color: #28c76f !important;
}

.asset-off {
    background-color: #82868b !important;
}

.asset-failure {
    background-color: #ef5258 !important;
}

.asset-noconnection {
    background-color: #f8f9fa !important;
}

.asset-severe {
    background-color: #ff9d54 !important;
}

.asset-iddle {
    background-color: #00cfe8 !important;
}

.with-icon {
    width: 40px;
}

.vue2leaflet-map {
    &.leaflet-container {
        height: 70.5vh;
    }
}

.text-header {
    //font-size: clamp(0.5em, 0.7em + 0.2vw, 5.5em);
    font-size: 1em;
}
/* 
//@media
@media screen and (min-height: 768px) {
    .vue2leaflet-map.leaflet-container {
        height: 54vh !important;
    }

    .card-journey {
        height: 64vh !important;
    }
}

@media screen and (min-height: 900px) {
    .vue2leaflet-map.leaflet-container {
        height: 60vh !important;
    }

    .card-journey {
        height: 68.6vh !important;
    }
}

@media screen and (min-height: 1024px) {
    .vue2leaflet-map.leaflet-container {
        height: 66vh !important;
    }

    .card-journey {
        height: 74vh !important;
    }
} */
</style><style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
